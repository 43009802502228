import { Link, navigate } from "gatsby"
import React, { useCallback, useState, useContext, useEffect } from "react"
import { Helmet } from "react-helmet"
import { useForm } from "react-hook-form"
import AuthenticationContext from "../context/authentication/AuthenticationContext"
import StateContext from "../context/state/StateContext"
import { getUser, makeLogin, resetPassword } from "../helpers/DrupalHelper"
import LayoutMinimal from "../components/Layout/Minimal"
import Button from "../components/Elements/Button"
import FloatingInput from "../components/Elements/Form/FloatingInput"
import NotificationContext from "../context/Notification/NotificationContext"
const Cols2 = props => {
  return (
    <div className="grid md:grid-cols-2 md:gap-[15px]">{props.children}</div>
  )
}

const ResetPassword = ({ location }) => {
  const {
    register,
    watch,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm()
  const { state, dispatch } = useContext(StateContext)
  const { toggleNotification } = useContext(NotificationContext)
  const { authentication, dispatchAuthentication, runLogin } = useContext(
    AuthenticationContext
  )
  // const [notMatch, setNotMatch] = useState(false)
  const [loading, setLoading] = useState(false)
  const [resetKey, setResetKey] = useState(null)
  const [userEmail, setUserEmail] = useState(null)

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search)
    const userResetKey = urlParams.get("t")
    setResetKey(userResetKey)

    const userEmail = urlParams.get("email")
    setUserEmail(userEmail)
  }, [])

  const onSubmit = async data => {
    setLoading(true)
    // setNotMatch(false)
    await resetPassword(state.token, data.email, data.password, resetKey).then(
      async res => {
        let dataL = await res.json()

        if (res.status === 200) {
          // dispatch({ type: "SET_TOKEN", payload: dataL.csrf_token })
          // dispatch({ type: "SET_LOGOUT_TOKEN", payload: dataL.logout_token })
          // console.log(dataL)
          // await getUser(dataL.csrf_token).then(async data => {
          //   // console.log(data)
          //   if (data.isAuthenticated === true) {
          //     sessionStorage.setItem("user", JSON.stringify(data))
          //     toggleNotification({
          //       content: `Welcome ${data.firstname}, login successful.`,
          //     })
          //     dispatchAuthentication({
          //       type: "LOGIN",
          //       payload: data,
          //     })
          //     console.log(data)
          //
          //     runLogin()
          //     navigate("/")
          //   }
          // })
          toggleNotification({
            content:
              "Congratulations, your new password has been saved. Login now to access O2O.",
          })
          navigate("/login")
        } else {
          // toggleNotification({ content: dataL.message, error: true })
          toggleNotification({ content: 'This link has expired or is invalid. Please initiate a new password reset request.', error: true })
        }
        // else {
        // dispatchAuthentication({
        //   type: "LOGIN",
        //   payload: data,
        // })
        // console.log(dataL)
        // setNotMatch(dataL.message)
        //  navigate("/login")
        // }
        reset()
      }
    )
    setLoading(false)
  }
  useEffect(() => {
    // console.log(location?.state?.logOut)
    // if (!location?.state?.logOut) {
    if (authentication.currentUserData.isAuthenticated) {
      navigate("/")
      // }
    }

    // console.log(authentication)
    // console.log(authentication)
    // console.log("authentication")
  }, [authentication])
  // if (loading)

  if (authentication.currentUserData.isAuthenticated === false && !loading) {
    return (
      <LayoutMinimal
        title={"Create New Password"}
        footer={"©2022 O2O. All rights reserved."}
        metaTitle={"Reset Your Password"}
      >
        {(userEmail === null || resetKey === null) ? (
          <>
            <p className="text-reg-18 text-center !mb-10">
              This link has expired or is invalid. If you have forgotten your password, <Link to="/forgot-password" className="red hover hover-red">initiate a new password reset</Link> instead.
            </p>
          </>
        ) : <form onSubmit={handleSubmit(onSubmit)}>
          <p className="text-reg-16 text-center mb-[20px]">
            Create a new password now to gain access to your account.
          </p>
          <FloatingInput
            label="Email address"
            hookForm
            name="email"
            // type="email"
            register={register}
            error={errors["email"]}
            required
            // disabled
            defaultValue={userEmail}
            value={userEmail}
          />
          <Cols2>
            <FloatingInput
              label="New Password"
              hookForm
              name="password"
              type="password"
              register={register}
              error={errors["password"]}
            />
            <FloatingInput
              label="Confirm New Password"
              hookForm
              name="password_repeat"
              type="password"
              passwordR={watch("password")}
              register={register}
              error={errors["password_repeat"]}
            />
          </Cols2>

          <div className="flex justify-center items-center mt-6">
            <Button red widthAuto type="submit">
              Reset Password
            </Button>
          </div>
        </form>}

      </LayoutMinimal>
    )
  }
  return (
    <div className="fixed top-0 left-0 right-0 bottom-0 w-full h-screen z-50 overflow-hidden flex flex-col items-center justify-center">
      <div className="loader ease-linear rounded-full border-2 border-t-2 border-gray-200 h-8 w-8"></div>
    </div>
  )
}

export default ResetPassword
